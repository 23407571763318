<template>
  <div
    v-if="condo != ''"
    class="condo-card"
    :class="{ active: condo.place_id == activeCondo }"
  >
    <div class="card-content pb-2">
      <figure class="image is-4by3">
        <img
          v-lazy="this.imageUrl"
          class="condo-image curve-edge"
          alt=""
          ref="condoImage"
        />
      </figure>

      <div class="media mt-5 mb-0">
        <div v-if="condo.profile" class="media-left ">
          <figure class="image is-32x32">
            <img :src="condo.profile" class="is-rounded" alt="" />
          </figure>
        </div>
        <div v-else class="media-left profile-loader-32">
          <figure class="image is-32x32" />
        </div>

        <div class="media-content">
          <p v-if="condo.info" class="title is-6 ellipsis">
            {{ condo.info.name }}
          </p>
          <p v-else class="title is-6 ellipsis">{{ condo.name }}</p>
          <p class="subtitle is-7 mb-4 ellipsis">
            Developed by
            <a href="" v-if="condo.info" class="has-text-dark">{{
              condo.info.developer
            }}</a>
          </p>
        </div>
      </div>

      <div class="level is-size-7 ellipsis">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item has-text-left">
            <span>
              <i class="fa fa-star has-text-primary" />
              <span class="title is-7">
                {{ condo.rating }} ({{ condo.user_ratings_total }}) </span
              ><br />
              <span class="has-text-info">on Google map</span>
            </span>
            <span v-if="!activeStation" class="ml-5">
              <i class="fa fa-map-marker-alt has-text-primary" />
              <span class="title is-7"> {{ this.stationName }} </span>
              <br />
              <span class="has-text-info">
                {{ this.stationDistance }}m to station</span
              >
              <br />
            </span>
            <span v-else class="ml-5">
              <i class="fa fa-road has-text-info" />
              <span class="title is-7">
                {{ parseInt(condo.station_distances[activeStation]) }}m
              </span>
              <br />
              <span class="has-text-info">to station</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="card-footer is-size-7"
      @click.stop="
        updateCondo(condo.place_id);
        viewCondo(condo.place_id, condo.info);
      "
    >
      <p class="card-footer-item p-0">
        <button
          v-if="condo.place_id == activeCondo"
          class="button is-primary is-small is-fullwidth arrow-button is-size-7"
        >
          See more
        </button>
        <button
          v-else
          class="button is-primary is-small is-outlined is-fullwidth arrow-button is-size-7"
        >
          See more
        </button>
      </p>
    </footer>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import gmaps from "@/utils/gmaps";
import { db, storage } from "@/firebase";

export default {
  name: "CondoCard",
  props: ["condo"],
  data() {
    return {
      imageUrl: undefined,
      isDrop: false,
      getPlacePhoto: gmaps.getPlacePhoto,
      stationName: "",
      stationDistance: "",
    };
  },
  created() {
    // this.getFirebaseImage()
    this.getCondoPhotos(this.condo.place_id);

    if (this.condo.highlight) {
      this.getNearestStationDetail(this.condo.station_distances);
    }
  },
  watch: {
    condoPhotos: function() {
      // console.log(this.condoPhotos);
    },
    imageUrl: function() {
      if (this.imageUrl == "") {
        // disable get place photos, since the cost goes up
        // this.imageUrl = this.getPlacePhoto(
        //   this.gmapsKey,
        //   this.condo.photos[0].photo_reference,
        //   300
        // );
        // console.log(this.imageUrl);
      }
    },
  },
  methods: {
    ...mapActions(["updateCondo", "fetchCondoPhotos"]),
    viewCondo(id, info) {
      var name = "";
      if (info == undefined) {
        name = this.condo.name;
      } else {
        name = info.name;
      }
      name = name
        .trim()
        .replaceAll("-", " ")
        .replaceAll(/  +/g, " ")
        .replaceAll(" ", "-");

      // same page
      router.push({
        name: "Condo",
        params: { id, name },
      });

      // new tab
      // let routeData = router.resolve({
      //   name: "Condo",
      //   params: { id, name },
      // });
      // window.open(routeData.href);
    },
    getCondoPhotos(condoId) {
      let _this = this;
      db.ref("condo_photos")
        .child(condoId)
        .once("value", (snapshot) => {
          var docs = [];
          snapshot.forEach((doc) => {
            docs.push(doc.val());
          });
          if (docs.length > 0) {
            for (var i = docs.length - 1; i > 0; i--) {
              var j = Math.floor(Math.random() * (i + 1));
              var temp = docs[i];
              docs[i] = docs[j];
              docs[j] = temp;
            }
            _this.imageUrl = docs[0].photo_url;
          } else {
            _this.imageUrl = "";
          }
        });
    },
    getFirebaseImage() {
      let _this = this;
      storage
        .ref()
        .child("condos/" + this.condo.place_id + "/gallery")
        .list({ maxResults: 1 })
        .then(function(res) {
          res.items.forEach(function(itemRef) {
            itemRef
              .getDownloadURL()
              .then(function(url) {
                _this.imageUrl = url;
                // this.$refs.condoImage.src = url;
              })
              .catch(function() {
                // console.log(error);
              });
          });
        });
    },
    getNearestStationDetail(distances) {
      let stationId = Object.keys(distances).reduce((a, b) =>
        distances[a] < distances[b] ? a : b
      );
      this.stationName = this.stations[stationId].name_en;
      this.stationDistance = parseInt(distances[stationId]);
    },
  },
  computed: {
    ...mapGetters([
      "stations",
      "gmapsKey",
      "activeStation",
      "activeLine",
      "activeCondo",
      "condoPhotos",
    ]),
    console: () => console,
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style></style>
