<template>
  <div
    id="condoList"
    :class="
      this.width == 'xs'
        ? 'columns is-multiline px-0'
        : 'columns is-multiline scrollable px-0'
    "
    :style="{
      height: '100%',
      overflow: this.width == 'xs' ? 'visible' : 'scroll',
    }"
  >
    <!-- Header -->
    <div class="column is-full">
      <br />
      <section class="section mt-5 pb-0">
        <div class="container" style="justify-content: 'flex-end'">
          <h1 class="title is-4">
            <el-row v-if="activeStation != ''">
              <el-col :span="12">
                <span class="has-text-weight-medium">
                  {{ activeStation }} -
                  {{ stations[activeStation].line }}
                  <span v-if="stations[activeStation]" class="has-text-primary">
                    {{ stations[activeStation].name_en }}
                  </span>
                  <br />
                  <span
                    class="has-text-weight-medium is-size-6 has-text-info ml-1"
                    >{{ Object.keys(condos).length }} properties
                  </span>
                </span>
              </el-col>
              <el-col :span="12" class="">
                <div class="has-text-right">
                  <!-- <span class="ml-4 mt-5">
                    <button class="button is-light is-small mr-2">clear</button>

                    <button
                      class="button is-light is-small mr-2"
                      @click="prevStation()"
                    >
                      Prev
                    </button>
                    <button
                      class="button is-light is-small"
                      @click="nextStation()"
                    >
                      Next
                    </button>
                  </span> -->

                  <el-button
                    type="info"
                    size="mini"
                    icon="el-icon-close"
                    class="neumorphism mb-3 mt-1"
                    plain
                    round
                    @click="
                      updateLineStation({ lineId: '', stationId: '' });
                      fetchStation('');
                      fetchCondos('');
                    "
                  >
                    clear</el-button
                  >

                  <el-button-group class="mt-1 ml-3">
                    <el-button
                      size="mini"
                      icon="el-icon-arrow-left"
                      @click="prevStation()"
                      class="neumorphism"
                      >prev</el-button
                    >
                    <el-button
                      size="mini"
                      @click="nextStation()"
                      class="neumorphism"
                      >next<i class="el-icon-arrow-right"></i
                    ></el-button>
                  </el-button-group>
                </div>
              </el-col>
            </el-row>

            <span v-else class="has-text-weight-normal">
              <span class="is-size-5">Recommended Properties 🏬 🚆</span>
            </span>
          </h1>
          <h2 v-if="activeStation == ''" class="subtitle is-6 pt-1 th">
            ค้นหาคอนโดมิเนียมใกล้รถไฟฟ้า โดยเลือกที่สถานีด้านล่าง
          </h2>
        </div>
      </section>
    </div>

    <!-- LineStations -->
    <div class="column is-full mb-3 px-5">
      <div class="columns is-multiline is-mobile">
        <slot v-for="(line, lineId) in lines">
          <div
            :key="lineId"
            v-if="width != 'xs' || lineId != 'ARL'"
            :class="
              width == 'md'
                ? 'column is-one-fifth-desktop is-one-fifth-tablet is-3-mobile has-text-centered px-5'
                : 'column is-one-fifth-desktop is-one-fifth-tablet is-3-mobile has-text-centered px-3'
            "
          >
            <!-- Dropdown -->
            <el-dropdown trigger="click" :style="{ width: '100%' }">
              <button
                class="line-button"
                v-bind:class="{ active: lineId == activeLine }"
              >
                <figure class="image is-32x32">
                  <img :src="'/' + line.image" alt="station line icon" />
                </figure>
              </button>

              <div class="mt-4 is-size-7 station-color px-2">
                <i class="fa fa-circle mr-1" :style="line.style"></i>
                {{ line.name }}
              </div>

              <el-dropdown-menu
                select-tag
                :class="{
                  'dropdown-stations-full': width == 'xs',
                  'dropdown-stations': width != 'xs',
                }"
                slot="dropdown"
              >
                <el-dropdown-item
                  v-for="(stationId, key) in line.stations"
                  :key="key"
                >
                  <div
                    v-if="stations[stationId]"
                    @click="
                      updateLineStation({ lineId, stationId });
                      fetchStation(stationId);
                      fetchCondos(stationId);
                    "
                  >
                    <el-row class="has-text-left">
                      <el-col :span="4"> {{ stationId }} </el-col>

                      <el-col class="ellipsis" :span="13">
                        {{ stations[stationId].line }}
                        {{ stations[stationId].name_en }}
                      </el-col>
                      <el-col class="ellipsis" :span="7">{{
                        stations[stationId].name_th
                      }}</el-col>
                    </el-row>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <!-- End dropdown -->
          </div>
        </slot>
      </div>
    </div>

    <slot v-if="width != 'xs'">
      <div
        v-for="(condo, condoId) in condos"
        :key="condoId"
        @click="updateCondo(condoId)"
        class="column is-half-desktop is-full-mobile is-full-tablet px-5 pt-0 mb-5"
      >
        <CondoCard :id="condoId" :condo="condo" :ref="condoId" />
      </div>
    </slot>
    <slot v-else>
      <div class="column is-full scrolling-wrapper-review pt-0 pl-5 mb-3">
        <CondoCardImage
          v-for="(condo, condoId) in condos"
          :key="condoId"
          :nearbyCondo="condo"
          cardHeight="160px"
          cardWidth="125px"
          @click="updateCondo(condoId)"
        />
      </div>
    </slot>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { scrollTo } from "vue-scrollto";
import router from "@/router";
import CondoCardImage from "@/components/condos/CondoCardImage";
import CondoCard from "@/components/condos/CondoCard";

export default {
  name: "CondoList",
  props: ["width"],
  components: {
    CondoCard,
    CondoCardImage,
  },
  watch: {
    stations: function() {
      if (this.$route.params.stationId != undefined) {
        this.updateLineStation({
          lineId: this.$route.params.lineId,
          stationId: this.$route.params.stationId,
        });
        this.fetchCondos(this.$route.params.stationId);
      }
    },
    $route() {
      if (this.$route.params.stationId == undefined) {
        this.fetchCondosHighlight();
      }
    },
    activeStation: function() {
      if (this.activeStation == "") {
        router.push({
          name: "Home",
        });
      } else if (this.$route.params.stationId != this.activeStation) {
        router.push({
          name: "Station",
          params: { lineId: this.activeLine, stationId: this.activeStation },
        });
        let station = this.stations[this.activeStation];
        document.title =
          station.line + " - " + station.name_en + " | CondoRail";
      }
    },
    activeCondo: function() {
      if (this.activeCondo != "") {
        scrollTo("#" + this.activeCondo, this.scrollOptions);
      }
    },
  },
  computed: {
    ...mapGetters([
      "activeLine",
      "activeStation",
      "condos",
      "activeCondo",
      "lines",
      "stations",
    ]),
    console: () => console,
  },
  data() {
    return {
      scrollOptions: {
        container: "#condoList",
        easing: "ease-in",
        duration: 300,
        offset: -100,
      },
    };
  },
  created() {
    // https://codepen.io/sethdavis512/pen/EvNKWw
  },
  methods: {
    ...mapActions([
      "updateCondo",
      "fetchStation",
      "updateLineStation",
      "fetchCondos",
      "fetchCondosHighlight",
    ]),
    handleStationCommand(command) {
      this.$message(command);
    },
    nextStation: function() {
      const line = this.lines[this.activeLine];
      const ind = line.stations.indexOf(this.activeStation) + 1;
      const lineId = this.activeLine;
      const stationId = line.stations[ind];
      if (ind < line.stations.length) {
        this.updateLineStation({ lineId, stationId });
        this.fetchCondos(stationId);
      }
    },
    prevStation: function() {
      const line = this.lines[this.activeLine];
      const ind = line.stations.indexOf(this.activeStation) - 1;
      const lineId = this.activeLine;
      const stationId = line.stations[ind];
      if (ind >= 0) {
        this.updateLineStation({ lineId, stationId });
        this.fetchCondos(stationId);
      }
    },
  },
};
</script>

<style></style>
