<template>
  <div class="container.is-fullhd">
    <el-row>
      <el-col
        :xs="24"
        :sm="11"
        :style="{ height: this.screenType == 'xs' ? '90%' : '100vh' }"
      >
        <CondoList :width="screenType" />
      </el-col>
      <el-col
        v-if="screenType != 'xs'"
        :xs="24"
        :sm="13"
        class="pb-0 px-0 py-0"
        style="border: 10px"
      >
        <keep-alive>
          <CondoListMap />
        </keep-alive>
      </el-col>

      <el-col v-else :xs="24" :sm="13" class="px-0 pb-5" style="border: 10px">
        <keep-alive>
          <div class="card map-shadow mx-4">
            <Map height="55vh" />
          </div>
        </keep-alive>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CondoList from "./CondoList";
import CondoListMap from "./CondoListMap";
import Map from "@/components/maps/Map";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    CondoList,
    CondoListMap,
    Map,
  },
  data() {
    return {};
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchLines();
    this.fetchStations();
    this.fetchCondosHighlight();
  },
  methods: {
    ...mapActions([
      "fetchLines",
      "fetchStations",
      "fetchCondosHighlight",
      "updateScreenType",
      "updateScreenWidth",
    ]),
    handleResize() {
      // screenType
      var width = window.innerWidth;
      if (width < 768) {
        this.updateScreenType("xs");
      } else if (width < 992) {
        this.updateScreenType("sm");
      } else {
        this.updateScreenType("md");
      }
      // screenWidth
      if (width > 1200) {
        this.updateScreenWidth("45%");
      } else {
        this.updateScreenWidth(
          Math.min(String(160 - parseInt(width / 10)), 100) + "%"
        );
      }
    },
  },
  computed: {
    ...mapGetters(["screenType"]),
    console: () => console,
  },
};
</script>

<style scoped>
/* #condoList {
  padding-bottom: 10vh !important;
} */
</style>
