var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:this.width == 'xs'
      ? 'columns is-multiline px-0'
      : 'columns is-multiline scrollable px-0',style:({
    height: '100%',
    overflow: this.width == 'xs' ? 'visible' : 'scroll',
  }),attrs:{"id":"condoList"}},[_c('div',{staticClass:"column is-full"},[_c('br'),_c('section',{staticClass:"section mt-5 pb-0"},[_c('div',{staticClass:"container",staticStyle:{"justify-content":"'flex-end'"}},[_c('h1',{staticClass:"title is-4"},[(_vm.activeStation != '')?_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('span',{staticClass:"has-text-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeStation)+" - "+_vm._s(_vm.stations[_vm.activeStation].line)+" "),(_vm.stations[_vm.activeStation])?_c('span',{staticClass:"has-text-primary"},[_vm._v(" "+_vm._s(_vm.stations[_vm.activeStation].name_en)+" ")]):_vm._e(),_c('br'),_c('span',{staticClass:"has-text-weight-medium is-size-6 has-text-info ml-1"},[_vm._v(_vm._s(Object.keys(_vm.condos).length)+" properties ")])])]),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"has-text-right"},[_c('el-button',{staticClass:"neumorphism mb-3 mt-1",attrs:{"type":"info","size":"mini","icon":"el-icon-close","plain":"","round":""},on:{"click":function($event){_vm.updateLineStation({ lineId: '', stationId: '' });
                    _vm.fetchStation('');
                    _vm.fetchCondos('');}}},[_vm._v(" clear")]),_c('el-button-group',{staticClass:"mt-1 ml-3"},[_c('el-button',{staticClass:"neumorphism",attrs:{"size":"mini","icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.prevStation()}}},[_vm._v("prev")]),_c('el-button',{staticClass:"neumorphism",attrs:{"size":"mini"},on:{"click":function($event){return _vm.nextStation()}}},[_vm._v("next"),_c('i',{staticClass:"el-icon-arrow-right"})])],1)],1)])],1):_c('span',{staticClass:"has-text-weight-normal"},[_c('span',{staticClass:"is-size-5"},[_vm._v("Recommended Properties 🏬 🚆")])])],1),(_vm.activeStation == '')?_c('h2',{staticClass:"subtitle is-6 pt-1 th"},[_vm._v(" ค้นหาคอนโดมิเนียมใกล้รถไฟฟ้า โดยเลือกที่สถานีด้านล่าง ")]):_vm._e()])])]),_c('div',{staticClass:"column is-full mb-3 px-5"},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_vm._l((_vm.lines),function(line,lineId){return _vm._t("default",[(_vm.width != 'xs' || lineId != 'ARL')?_c('div',{key:lineId,class:_vm.width == 'md'
              ? 'column is-one-fifth-desktop is-one-fifth-tablet is-3-mobile has-text-centered px-5'
              : 'column is-one-fifth-desktop is-one-fifth-tablet is-3-mobile has-text-centered px-3'},[_c('el-dropdown',{style:({ width: '100%' }),attrs:{"trigger":"click"}},[_c('button',{staticClass:"line-button",class:{ active: lineId == _vm.activeLine }},[_c('figure',{staticClass:"image is-32x32"},[_c('img',{attrs:{"src":'/' + line.image,"alt":"station line icon"}})])]),_c('div',{staticClass:"mt-4 is-size-7 station-color px-2"},[_c('i',{staticClass:"fa fa-circle mr-1",style:(line.style)}),_vm._v(" "+_vm._s(line.name)+" ")]),_c('el-dropdown-menu',{class:{
                'dropdown-stations-full': _vm.width == 'xs',
                'dropdown-stations': _vm.width != 'xs',
              },attrs:{"slot":"dropdown","select-tag":""},slot:"dropdown"},_vm._l((line.stations),function(stationId,key){return _c('el-dropdown-item',{key:key},[(_vm.stations[stationId])?_c('div',{on:{"click":function($event){_vm.updateLineStation({ lineId: lineId, stationId: stationId });
                    _vm.fetchStation(stationId);
                    _vm.fetchCondos(stationId);}}},[_c('el-row',{staticClass:"has-text-left"},[_c('el-col',{attrs:{"span":4}},[_vm._v(" "+_vm._s(stationId)+" ")]),_c('el-col',{staticClass:"ellipsis",attrs:{"span":13}},[_vm._v(" "+_vm._s(_vm.stations[stationId].line)+" "+_vm._s(_vm.stations[stationId].name_en)+" ")]),_c('el-col',{staticClass:"ellipsis",attrs:{"span":7}},[_vm._v(_vm._s(_vm.stations[stationId].name_th))])],1)],1):_vm._e()])}),1)],1)],1):_vm._e()])})],2)]),(_vm.width != 'xs')?_vm._t("default",_vm._l((_vm.condos),function(condo,condoId){return _c('div',{key:condoId,staticClass:"column is-half-desktop is-full-mobile is-full-tablet px-5 pt-0 mb-5",on:{"click":function($event){return _vm.updateCondo(condoId)}}},[_c('CondoCard',{ref:condoId,refInFor:true,attrs:{"id":condoId,"condo":condo}})],1)})):_vm._t("default",[_c('div',{staticClass:"column is-full scrolling-wrapper-review pt-0 pl-5 mb-3"},_vm._l((_vm.condos),function(condo,condoId){return _c('CondoCardImage',{key:condoId,attrs:{"nearbyCondo":condo,"cardHeight":"160px","cardWidth":"125px"},on:{"click":function($event){return _vm.updateCondo(condoId)}}})}),1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }